import './Button.scss';

const Button = props => {
    return (
        <div {...props} className={"Button " + ( props.className ? props.className : '' ) }>
            { props.children }
        </div>
    );
}
  
export default Button;
  