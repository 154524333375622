import Fade from 'react-reveal/Fade';

import "./Hero.scss"

const Hero = () => {
  return (
    <div className="Hero">
      <Fade left>
        <div className="Content">
            <div className="LogoContianer">
              <div className="Logo"/><span>Inc.</span>
            </div>
            <div className="Text">
              <div className="Title">Artificial Intelligence.<br/><span className="SubTitle">Easy. Simple.</span></div>
              <p>
                Empower your games with AI. Create more compelling gameplay and a better game experience today.
              </p>
            </div>
        </div>
      </Fade>
      <div className="Slanted">
        <div>
          <Fade up>
            <div className="img1"/>
          </Fade>
          <span/>
          <Fade up>
            <div className="img2"/>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Hero;
