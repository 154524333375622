import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./components/Home";
import Console from "./components/Console";

function App() {
  return (
    <>
      <div style={{ width : "100%", padding: "20px", textAlign: 'Center' }}>THIS WEBSITE IS CURRENTLY BEING WORKED ON. PLEASE CHECK BACK LATER, THANK YOU</div>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          
          <Route exact path="/console">
            <Console/>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
