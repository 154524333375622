import "./Home.scss"

import Nav from "../Nav/";
import Footer from "../Footer/";
import Hero from "./Hero/";
import Pricing from "./Pricing/";
import Section1 from "./Section1/";
import Agents from "./Agents/";
import Section2 from "./Section2/";

const Home = () => {
  return (
    <div className="Home">
        <Nav/>
        <Hero/>
        <div className="Content">
          <div>
            <Pricing/>
          </div>
          <div>
            <Section1/>
          </div>
          <div>
            <Agents/>
          </div>
          <div>
            <Section2/>
          </div>
        </div>
        <Footer/>
    </div>
  );
}

export default Home;
