import Fade from 'react-reveal/Fade';

import './Card.scss';

const Card = props => {
    return (
        <Fade up>
            <Fade>
                <div className={ " Card " + props.className }>
                    { props.children }
                </div>
            </Fade>
        </Fade>
    );
  }
  
  export default Card;