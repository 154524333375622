import './Agents.scss';

import Card from "../../Card"

const Agents = () => {
    return (
        <div className="Agents">
            <div className="Title">Supported Algorithms</div>
            <div className="AgentList">
                <Card className="Agent-1"></Card>
                <Card className="Agent-2"></Card>
                <Card className="Agent-3"></Card>
            </div>
        </div>
    );
  }
  
export default Agents;
  