import './Section2.scss';

const Section2 = () => {
    return (
      <div className="HomeSection2">
        <div className="Content Regular">
          <div className="Text">
            <div className="SubTitle">Training an AI on your own is difficult, and expensive</div>
            <p>
              Having smart and adaptive non-playable characters brings a whole new dynamic to your games.
              Have your AI characters continuously learn and adapt to your players while we train and deploy everything in the background.
            </p>
          </div>
          <div className="Imgs">
            <div className="Img_1_1"/>
            <div className="Img_1_2"/>
          </div>
        </div>
        <div className="Content Reverse">
          <div className="Imgs">
            <div className="Img_2_1"/>
            <div className="Img_2_2"/>
          </div>
          <div className="Text">
            <div className="SubTitle">Quick. Easy. Affordable.</div>
            <p>
              Setup is simple when using our service.
              Create an account, pick your configuration, and add our code.
              Accelerate game development and enhance player interaction today!
            </p>
          </div>
        </div>
      </div>
    );
  }
  
export default Section2;