import './Footer.scss';

const Footer = () => {
    return (
        <div className="Footer">
            <div className="Section1">

            </div>
            <div className="Section2">
                
            </div>
        </div>
    );
  }
  
  export default Footer;
  