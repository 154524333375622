import './Pricing.scss';

import Card from "../../Card/"

const Pricing = () => {
    return (
        <div className="Pricing">
            <div className="Title">Plans & Pricing</div>
            <div className="CardList">
                <Card className="Price-1">
                    <div className='ImgContainer'>
                        <div className="Img1"/>
                    </div>
                    <span className="Divider"/>
                    <div className="SubText">Basic</div>
                    <div className="Num">Free</div>
                    <div className="Text"></div>
                </Card>
                <Card className="Price-2">
                    <div className='ImgContainer'>
                        <div className="Img2"/>
                    </div>
                    <span className="Divider"/>
                    <div className="SubText">Starter</div>
                    <div className="Num">$15<span className="SubNum">/mo</span></div>
                    <div className="Text"></div>
                </Card>
                <Card className="Price-3">
                    <div className='ImgContainer'>
                        <div className="Img3"/>
                    </div>
                    <span className="Divider"/>
                    <div className="SubText">Pro</div>
                    <div className="Num">$99<span className="SubNum">/mo</span></div>
                    <div className="Text"></div>
                </Card>
            </div>
        </div>
    );
  }
  
export default Pricing;