import { useHistory } from 'react-router-dom';

import Button from '../Button';
import './Nav.scss';

const Nav = () => {

    const history = useHistory();
  
    function toConsole() {
      history.push("/console");
    }

    return (
            <div className="Navbar">
                <Button className="test" onClick={ toConsole }>Go to console</Button>
            </div>
    );
  }
  
  export default Nav;
  